@use '../../../../../colors';
.MainNav {
  text-transform: uppercase;
  font-size: 16px;
  @media (min-width: 767px) {
    height: 48px;
    display: flex;
    align-items: center;
  }
  @media (max-width: 767px) {
    position: fixed;
    bottom: 0;
    font-size: 11px;
    left: 0;
    right: 0;
    border-top: 1px solid colors.$color-background;
    background-color: white;
  }
}

.MainNav__link {
  color: colors.$color-text-main;

  display: flex;
  flex-direction: column;
  align-items: center;

  &:visited,
  :hover,
  :focus,
  :active {
    color: colors.$color-text-main;
  }
  &:hover {
    @media (min-width: 768px) {
      border-bottom: 6px solid orange;
    }
  }
  @media (min-width: 767px) {
    display: inline;
  }
}

.MainNav__list {
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.MainNav__item {
  margin-right: 30px;
  @media (max-width: 767px) {
    margin-right: 0;
    flex-basis: 20%;
    padding-top: 12px;
    flex-grow: 1;
    text-align: center;
    padding-bottom: 12px;
  }
}

.MainNav__item-icon {
  width: 25px;
  height: 25px;
}
