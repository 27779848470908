@use '../../../../colors';
.Footer-content {
  display: flex;
  padding: 0 16px;
  font-size: 16px;
  flex-direction: column;
  max-width: 1200px;
  margin: auto;
  align-items: center;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  @media (min-width: 768px) {
    padding: 0 40px;
  }
  .anticon {
    font-size: 30px;
  }
  .Footer__sections {
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 600px;
    @media (min-width: 768px) {
      font-size: 20px;
    }
    .Footer__sections-block {
      display: flex;
      flex-direction: column;
      a {
        @media (min-width: 768px) {
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }
    }
  }
  .Footer__contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    color: colors.$color-text-main;
    @media (min-width: 768px) {
      margin-top: 0;
      flex-direction: row;
      align-items: baseline;
    }
    .Footer__contacts-block {
      display: flex;
      font-size: 20px;
      list-style-type: none;
      &:first-of-type {
        margin-bottom: 10px;
        @media (min-width: 768px) {
          margin-right: 20px;
          margin-bottom: 0px;
        }
      }
      a {
        color: colors.$color-text-main;
        display: flex;
        align-items: center;
        padding: 5px;
        white-space: nowrap;
        &:hover {
          color: colors.$color-action;
          svg {
            path {
              fill: colors.$color-action;
            }
          }
        }
        svg {
          margin-right: 5px;
          width: 30px;

          path {
            fill: colors.$color-text-main;
          }
        }
      }
    }
  }
}

.Footer__copyright {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.Footer__logo {
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    font-size: 24px;
  }
}

.Footer__section {
}
