.AdminActions {
  display: flex;
  flex-direction: column;
  padding: 20px;
  .AdminActions__to-admin-page {
    margin-bottom: 10px;
  }
  @media (min-width: 768px) {
    flex-direction: row;
    .AdminActions__to-admin-page {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
}
