@use '../../../../colors';

.Header {
  display: flex;
  top: 0;
  align-items: center;
  position: fixed;
  background: white;
  width: 100%;
  z-index: 1001;
  background: colors.$color-background;
  .Header__logo {
    margin-left: 54px;
    font-size: 24px;
    font-weight: bold;
    color: colors.$color-text-accent;
    margin-right: 40px;
    @media (min-width: 768px) {
      margin-left: 42px;
    }
    @media (min-width: 1280px) {
      margin-left: 84px;
    }
  }
  .Header__menu {
    height: calc(100vh - 37px);
    position: absolute;
    top: 37px;
    @media (max-width: 767px) {
      width: 200px;
    }
    .Header__link {
      padding: 2px;
    }
  }
  .Header__home {
    color: colors.$color-text-accent;
    position: absolute;
    right: 80px;
    font-size: 16px;
    cursor: pointer;
    height: 100%;
    display: inline-flex;
    align-items: center;
  }
  .Header__cart {
    color: colors.$color-text-accent;
    position: absolute;
    right: 28px;
    font-size: 28px;
    cursor: pointer;
    display: inline-flex;
    .Header__cart-amount {
      position: absolute;
      right: 0;
      bottom: 8px;
      font-size: 10px;
      color: white;
      background: red;
      border-radius: 50%;
      width: 14px;
      height: 14px;
      text-align: center;
    }
  }
}
