@use "sass:color";
$color-text-accent: color.adjust(#3b444b,  $lightness: -10%);
$color-text-main: #3b444b;
$color-background: #f2f2f2;
$color-action: #1890ff;

$root-font-size: 1rem;
$font-family: 'Roboto', sans-serif;
$page-width: 800px;
$paragraph-font-size: 1.6rem;
