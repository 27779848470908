@use "sass:color";
@use '../../../colors';
$gutter: 1px;
.Gallery {
  width: 100%;
  .Gallery__navigation {
    display: flex;
    position: absolute;
    bottom: 10px;
    align-items: center;
    left: 50%;
    transform: translate(-50%);
    .Gallery__navigation-dot {
      cursor: pointer;
      border-radius: 50%;
      width: 5px;
      height: 5px;
      background: #ccc;
      &:not(:last-child) {
        margin-right: 8px;
      }
      &.Gallery__navigation-dot_active {
        background: color.adjust(#ccc, $lightness: 80%);
      }
    }
  }
  .Gallery__images {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$gutter;
    margin-right: -$gutter;
    .Gallery__image {
      position: relative;
      background-color: #eee;
      box-sizing: border-box;
      padding: $gutter;
      width: 100%;
      &:first-child {
        @media (min-width: 768px) {
          width: 100%;
        }
      }
      @media (min-width: 1024px) {
        width: 50%;
      }
      img {
        cursor: pointer;
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
