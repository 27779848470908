@use 'colors';
html {
  min-height: 100%;
  height: initial;
  position: relative;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: colors.$color-text-main;
  min-height: 100%;
  .Layout {
    position: relative;
    margin: 34px 16px 0;
    padding-bottom: 440px;
    @media (min-width: 768px) {
      margin: 48px 42px 0;
      padding-bottom: 370px;
    }
    @media (min-width: 1280px) {
      margin: 48px 84px 0;
    }
  }
  .overlay {
    height: 100%;
    width: 100%;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    background: rgba(211, 211, 211, 0.1);
    pointer-events: none;
    &.overlay_visible {
      display: block;
    }
  }
  &.hidden-mobile-menu {
    overflow: hidden;
    .overlay {
      display: block;
    }
  }
}
* {
  box-sizing: border-box;
}
.ant-upload.ant-upload-select-picture-card {
  height: auto;
}
.ant-modal-root {
  .ant-modal-mask,
  .ant-modal-wrap {
    z-index: 1002;
  }
  .ant-modal-body {
    max-height: 80vh;
    overflow-y: auto;
  }
}
address {
  margin: 0;
}
.Footer {
  height: 410px;
  width: 100%;
  background: colors.$color-background;
  position: absolute;
  bottom: 0;

  @media (min-width: 768px) {
    height: 260px;
  }
}

a {
  text-decoration: none;
}

.page404 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    text-align: center;
    white-space: nowrap;
    @media (min-width: 768px) {
      font-size: 30px;
    }
  }
  * {
    box-sizing: content-box;
  }
  .goggle-container {
    display: none;
    width: 150px;
    height: 70px;
    -moz-perspective: 500px;
    -ms-perspective: 500px;
    -webkit-perspective: 500px;
    perspective: 500px;
    -webkit-perspective-origin: 0 -50px;
    perspective-origin: 0 -50px;
    position: relative;
    margin-bottom: 20px;
    @media (min-height: 500px) {
      display: block;
    }
  }

  .goggle {
    width: 150px;
    height: 70px;
    position: absolute;
    margin: 0 auto;
    top: 0;
    left: 0;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;

    -webkit-animation: rotate 10s linear infinite;
    animation: rotate 10s linear infinite;
  }

  .goggle .glass {
    border: 8px solid #e95035;
    border-radius: 5px;
    width: 40px;
    height: 20px;
    position: absolute;
    top: 20px;
    background-color: rgba(255, 255, 255, 0.25);
    -webkit-transform: translateZ(34px);
    transform: translateZ(34px);
  }

  .goggle .glass.left {
    left: 10px;
  }

  .goggle .glass.right {
    right: 10px;
  }

  .goggle .bridge {
    position: absolute;
    border-top: 8px solid #e95035;
    top: 30px;
    left: 60px;
    width: 28px;
    -webkit-transform: translateZ(34px);
    transform: translateZ(34px);
  }

  .goggle .leg {
    position: absolute;
    border-top: 8px solid #e95035;
    border-right: 8px solid #e95035;
    border-top-right-radius: 8px;
    height: 15px;
    width: 60px;
    top: 28px;
    -webkit-transform: rotateY(90deg);
    transform: rotateY(90deg);
  }

  .goggle .leg.left {
    left: -23px;
  }

  .goggle .leg.right {
    right: -23px;
  }

  @-webkit-keyframes rotate {
    0% {
      -webkit-transform: rotateY(0deg);
    }

    100% {
      -webkit-transform: rotateY(360deg);
    }
  }

  @keyframes rotate {
    0% {
      transform: rotateY(0deg);
    }

    100% {
      transform: rotateY(360deg);
    }
  }
}

.react-images__view-image--isModal {
  max-height: 100vh !important;
}

.page:not(.IndexPage) {
  padding-top: 20px;
}

.ant-modal-body {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 5px 5px 5px rgba(224, 224, 224, 0.2);
    box-shadow: inset 5px 5px 5px rgba(224, 224, 224, 0.2);
    border-radius: 6px;
  }
  &::-webkit-scrollbar {
    height: 5px;
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    -webkit-box-shadow: inset 0 5px 16px #d6dade;
    box-shadow: inset 0 5px 16px #d6dade;
  }
}
a:focus:not(.not-focus),
button:focus {
  box-shadow: 0 0 0 3px #6688dd;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  display: none;
}
