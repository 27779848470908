@use '../../../../colors';
.CartItem {
  display: flex;
  position: relative;
  align-items: center;
  font-size: 18px;
  color: colors.$color-text-main;
  margin-bottom: 10px;
  &:hover {
    color: colors.$color-text-main;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    align-items: flex-start;
  }
  .CartItem__image {
    width: 100px;
    height: auto;
    @media (min-width: 768px) and (max-width: 1024px) {
      width: 50px;
    }
  }
  .CartItem__content {
    margin-left: 10px;
    .CartItem__title {
      cursor: pointer;
      justify-content: space-between;
      font-weight: bold;
      display: flex;
      align-items: center;
    }
  }
  .CartItem__remove-icon {
    margin-left: 10px;
    color: red;
    cursor: pointer;
  }
}
